<template>
  <div
    class="flex items-center"
    :class="vertical ? 'flex-col justify-center text-xs' : 'text-sm'"
  >
    <p v-if="!hideInfoText" class="text-slate-100 leading-none mr-2">
      Show values in
    </p>
    <span
      :class="{
        'mr-2' : !vertical,
        'text-slate-100': prizeValueCurrency,
      }"
    >
      USD
    </span>
    <CheckboxSwitch
      :id="id"
      v-model="prizeValueCurrency"
      :small="small"
      :class="{ 'rotate-90 my-3 ml-2': vertical }"
    />
    <span
      :class="{
        'mr-2' : !vertical,
        'text-slate-100': !prizeValueCurrency,
      }"
    >
      Crypto
    </span>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useUiStore } from '@/store/ui';

defineProps({
  id: {
    type: String,
    required: true,
  },
  vertical: Boolean,
  hideInfoText: Boolean,
  small: Boolean,
});

const uiStore = useUiStore();
const {
  uiSettings,
} = storeToRefs(uiStore);

const prizeValueCurrency = ref(uiSettings.value.prizeValueCurrency === 'Crypto');

watchEffect(() => {
  uiSettings.value.prizeValueCurrency = !prizeValueCurrency.value ? 'USD' : 'Crypto';
});
</script>
